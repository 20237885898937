<template>
  <div class="index">
    <div class="resources-content">
        <div class="select-wrapper">
          <div class="select-left">
            <span class="text">实训状态</span>
              <el-select v-model="seletedOption"  clearable value-key="name" style="width: 72%" placeholder="请选择" @change="optionFun">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :value="item.value"
                    :label="item.name"         
                  ></el-option>
              </el-select>
          </div>
          <div class="select-left">
              <el-button type="primary" @click="add">创建训练</el-button>
          </div>
      </div>
      <div class="scro">
      <el-scrollbar>
          <el-table
          :data="practicalTable"
          style="width: 100%; flex: 1"
          class="customTable"
        >
          <el-table-column prop="train_name" width="340px" label="实训名称"></el-table-column>
          <el-table-column prop="status" label="实训状态">
              <template slot-scope="scope">
                    <span v-if="scope.row.status==1">未开始</span>
                    <span v-if="scope.row.status==2">进行中</span>
                    <span v-if="scope.row.status==3">已结束</span>
              </template>
          </el-table-column>
          <el-table-column prop="class_num" label="班级人数">
              <template slot-scope="scope">
                  <span>{{scope.row.class_num}}人</span>
              </template>
          </el-table-column>
          <el-table-column prop="create_time" label="创建时间"></el-table-column>
          <el-table-column prop="start_time" label="开始时间"></el-table-column>
          <el-table-column prop="end_time" label="结束时间"></el-table-column>
          <el-table-column label="操作" align="center" width="340px">
            <template slot-scope="scope">       
              <div class="link-list" v-if="scope.row.status==2">
                <el-link
                  type="primary"
                  :underline="false"
                  @click="examine(scope.row)"
                  >查看实训</el-link
                >
                <el-link
                  type="primary"
                  :underline="false"
                  @click="trainingStatus(scope.row.id,2)"
                  >结束实训</el-link
                >
                <el-link
                  type="primary"
                  :underline="false"
                  @click="editClassify(scope.row)"
                  >编辑</el-link
                >
                <el-link
                  type="danger"
                  :underline="false"
                  @click="deleteClassifyRow(scope.row)"
                  >删除</el-link
                >
              </div>
              <div class="link-list" v-if="scope.row.status==1">
               
                <el-link
                  type="primary"
                  :underline="false"
                  @click="trainingStatus(scope.row.id,1)"
                  >开始实训</el-link
                >
                <el-link
                  type="primary"
                  :underline="false"
                  @click="editClassify(scope.row)"
                  >编辑</el-link
                >
                <el-link
                  type="danger"
                  :underline="false"
                  @click="deleteClassifyRow(scope.row)"
                  >删除</el-link
                >
              </div>
              <div class="link-list" v-if="scope.row.status==3">
               
                <el-link
                  type="danger"
                  :underline="false"
                  @click="deleteClassifyRow(scope.row)"
                  >删除</el-link
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-scrollbar>
   
      </div>
      <el-pagination
        class="pages-center"
        :current-page="coursePages.currentPageNum"
        :page-size="coursePages.eachPageNum"
        layout="prev, pager, next, jumper"
        :total="coursePages.total"
        @current-change="courseCurrentChange"
      >
      </el-pagination>
    
    </div>
  </div>
</template>

<script>
import {practicalListData,changeStatus,practicalDel} from '@/utils/apis'
export default {
  name: "management",
  data() {
    return {
        practicalTable:[],
        options:[
           {
            id:'',
            name:'全部',
            value:''
          },
          {
            id:1,
            name:'未开始',
            value:1
          },
           {
            id:2,
            name:'进行中',
            value:2
          },{
            id:3,
            name:'已结束',
            value:3
          }
        ],
        num_id:'',
        seletedOption:'',
         //分页
        coursePages: {
          currentPageNum: 1,
          eachPageNum: 10,
          total: 0,
        },
    
    };
  },

  mounted(){
  this.getList()
  },

  methods: {
        //实训状态
        optionFun(){
          this.num_id = ''
          this.getList()
        },

        //查看实训
        examine(row){
          this.$router.push({
                path: '/school/practical/streaming',query:{id:row.id}
            })
        },
        


        //创建训练
        add(){
        this.$router.push({
                path: '/school/practical/addDrill'
            })
        },
        //实训状态 开始 结束
        trainingStatus(id,status){
            
             let param = {
              id:id,
              status:status,
            }
            if(status ==2){
                  this.$confirm(`确定结束该场实训？`, '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning'
                }).then(() => {
                    changeStatus(param).then((res) => {
                        if(res.code ==200){
                            this.getList()
                        }
                    }).catch((err) => {
                        console.error('err', err);
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            }else{
                changeStatus(param).then((res) => {
                    if(res.code ==200){
                        this.getList()
                    }
                }).catch((err) => {
                    console.error('err', err);
                });
            }
        
        },
      
       //获取列表
        getList(){
           let param = {
              id:this.num_id,
              status:this.seletedOption,
              paging: 1,
              page:this.coursePages.currentPageNum,
              pageSize:this.coursePages.eachPageNum
            }
            practicalListData(param).then((res) => {
                if(res.code ==200){
                 this.practicalTable = res.data.list
                 this.coursePages.total = res.data.total;
                }
                
            }).catch((err) => {
                console.error('err', err);
            });
        },

      
      //重置表单
     resetForm() {
        this.$refs.addClassifyForm.resetFields();
        this.addClassifyForm = this.$options.data().addClassifyForm;
      },

      //编辑
      editClassify(row){
        this.num_id = row.id
         let param = {
              id:this.num_id,
            }
            practicalListData(param).then((res) => {
                if(res.code ==200){
                     this.$router.push({
                        path: '/school/practical/addDrill',query: { id: row.id,data:res.data }  
                    })
                }               
            }).catch((err) => {
                console.error('err', err);
            });
       
      },

    //创建分类
  

    //删除列表
    deleteClassifyRow(row){
     this.$confirm(`是否删除该实训，删除将无法恢复`, '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning'
                }).then(() => {
                    let param = {
                      id:row.id	
                    }
                    practicalDel(param).then((res) => {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                            duration:1000,
                            onClose: () => {
                                this.getList()
                            }
                        })
                    }).catch((err) => {
                        console.error('err', err)
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
    },

    // 切换分页
    courseCurrentChange(val) {
      this.coursePages.currentPageNum = val;
       this.getList()
    },
  },
};
</script>



<style  lang="scss" scoped>
.index {
  box-sizing: border-box;
  height: 100%;
  padding: 20px 20px 0;
}
.resources-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .management-admin-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    height: 50px;
  }
 
}

.select-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  .select-left {
       display: flex;
        align-items: center;
        margin-bottom: 20px;
    .text {
      font-size: 14px;
      color: #333333;
      margin-right: 10px;
    }
    .resources-cascader {
      width: 420px;
      ::v-deep .el-input__inner {
        border-radius: 0;
      }
    }
  }
  .select-btn {
    i {
      font-size: 12px;
      margin-right: 5px;
    }
  }
 
}
.scro{
  height: 80%;
  .el-scrollbar {
          height: 100%;
  }

}
.customTable th.el-table__cell {
  background-color: #f0f3fc !important;
}
.dialog-footer {
  text-align: center;
  margin-top: 20px;
}
.pages-center {
  margin: 20px 0 20px;
  text-align: center;
}
.demo-ruleForm .el-form-item__content{
  display:inline-block;
}
.demo-form-inline{
      display: flex;
    flex-direction: column;
    padding: 0 30px;
}
.el-dialog__title {
      border-left: 1px solid #000 !important;
}

</style>